<template>
    <div>
        <el-card class="box-card"
                 v-if="userPermissions.indexOf('inquiry_all_projects') !== -1">
            <TableList :tableData="tableData" :tablePagination="tablePagination" @getTableData="getAllData" :type="'all'"/>
        </el-card>
    </div>
</template>

<script>
  import TableList from './components/tableList'
  import { mapGetters } from 'vuex'


  export default {
    name: 'index',
    components: { TableList },
    computed: {
      ...mapGetters(['userPermissions', 'userInfo'])
    },
    data() {
      return {
        tableData: [],
        drawerVisible: false,
        rowId: 'add',
        tablePagination: {
          total: 0,
          page_size: 15,
          offset: 0,
          current_page: 1
        }
      }
    },
    methods: {
      async getAllData(val) {
        let searchCondition
        if (!val) {
          searchCondition = {
            page_size: 15,
            current_page: 1
          }
        } else searchCondition = val
        let { list, page_info } = await this.$api.listInquiryProject(searchCondition)

        this.$nextTick(() => {
          let convertData = ['is_assign', 'is_platform_pay', 'is_send_back', 'is_cart', 'is_auth_price']
          list.forEach((item) => {
            convertData.forEach((i) => {
              if (i === 'is_assign' || i === 'is_send_back') {
                item[i] = item[i] === 1 ? '是' : '否'
              } else item[i] = item[i] === 2 ? '是' : '否'
            })
          })
          this.tableData = list
          this.tablePagination = page_info
        })
      }
    },
    mounted() {
      this.getAllData()
    }
  }
</script>

<style scoped>

</style>
